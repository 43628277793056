import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'whitelableRegister',
        path: `${AUTH_PREFIX_PATH}/register/whitelabel`,
        component: React.lazy(() => import('views/auth-views/authentication/whitelabel')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `reset-password`,
        component: React.lazy(() =>import('views/auth-views/authentication/reset-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards')),
    },
    {
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component:  React.lazy(() => import('views/app-views/profile')),
    },
    {
        key: 'teams',
        path: `${APP_PREFIX_PATH}/teams`,
        component: React.lazy(() => import('views/app-views/teams')),
    },
    {
        key: 'whitelabel',
        path: `${APP_PREFIX_PATH}/whitelabel`,
        component: React.lazy(() => import('views/app-views/whitelabel'))
    },
    {
        key: 'whitelabelusers',
        path: `${APP_PREFIX_PATH}/whitelabel/users`,
        component: React.lazy(() => import('views/app-views/whitelabel/users'))
    },
    {
        key: 'demo',
        path: `${APP_PREFIX_PATH}/demo`,
        component: React.lazy(() => import('views/app-views/demo'))
    },
    {
        key: 'library',
        path: `${APP_PREFIX_PATH}/library`,
        component: React.lazy(() => import('views/app-views/library'))
    },
    {
        key: 'campaigns',
        path: `${APP_PREFIX_PATH}/campaigns`,
        component: React.lazy(() => import('views/app-views/campaigns'))
    },
    {
        key: 'content',
        path: `${APP_PREFIX_PATH}/content`,
        component: React.lazy(() => import('views/app-views/campaigns'))
    },
    {
        key: 'projects',
        path: `${APP_PREFIX_PATH}/projects`,
        component: React.lazy(() => import('views/app-views/projects'))
    },
    {
        key: 'voicer',
        path: `${APP_PREFIX_PATH}/voicer`,
        component: React.lazy(() => import('views/app-views/voicer'))
    },
    {
        key: 'voicerlist',
        path: `${APP_PREFIX_PATH}/voicer-list`,
        component: React.lazy(() => import('views/app-views/voicer/VoicerList'))
    },
    {
        key: 'audiobooks',
        path: `${APP_PREFIX_PATH}/audiobooks`,
        component: React.lazy(() => import('views/app-views/audiobooks'))
    },
    {
        key: 'audiobookslist',
        path: `${APP_PREFIX_PATH}/audiobooks-list`,
        component: React.lazy(() => import('views/app-views/audiobooks/AudiobooksList'))
    },
    {
        key: 'articles',
        path: `${APP_PREFIX_PATH}/articles`,
        component: React.lazy(() => import('views/app-views/articles'))
    },
    {
        key: 'ssmlEditor',
        path: `${APP_PREFIX_PATH}/ssml-editor`,
        component: React.lazy(() => import('views/app-views/ssml-editor'))
    },
    {
        key: 'voiceEmotion',
        path: `${APP_PREFIX_PATH}/voice-emotion`,
        component: React.lazy(() => import('views/app-views/voicemotion'))
    },
    {
        key: 'voiceEmotionList',
        path: `${APP_PREFIX_PATH}/voice-emotion/list`,
        component: React.lazy(() => import('views/app-views/voicemotion/List'))
    },
    {
        key:'transcribelist',
        path:`${APP_PREFIX_PATH}/transcribe/list`,
        component: React.lazy(() => import('views/app-views/transcribe'))
    },{
        key:'transcribe',
        path:`${APP_PREFIX_PATH}/transcribe`,
        component: React.lazy(() => import('views/app-views/transcribe/Transcribe'))
    }

]